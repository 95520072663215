import React from 'react';
import QRCode from 'react-qr-code';
const APP_URL = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : `${process.env.REACT_APP_LOCAL_SERVER_URL}:3000`;

const JudgeQRCodeComponent = ({ judgeId }) => {
  return (
    <div style={{ height: 'auto', margin: '0 auto', maxWidth: 200, width: '100%' }}>
      <QRCode
        size={256}
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={`${APP_URL}/judge-view/${judgeId}/all`}
        viewBox={`0 0 256 256`}
      />
    </div>
  );
};

export default JudgeQRCodeComponent;
