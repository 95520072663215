import React, { useState, useEffect } from 'react';
import ScoringQuestionRow from './ScoringQuestionRow';
import AddScoringQuestionButton from './AddScoringQuestionButton';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const AllScoringQuestions = ({ eventId }) => {
  const [scoringQuestions, setScoringQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAllScoringQuestionsOfEvent = async () => {
    try {
      const { data } = await axios.get(`/api/events/get-by-id/${eventId}`);
      setScoringQuestions([...data.scoringQuestions]);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllScoringQuestionsOfEvent();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className='row'>
        <h2>Scoring Questions</h2>
        <div className='actions-container'>
          <AddScoringQuestionButton eventId={eventId} onSuccess={getAllScoringQuestionsOfEvent} />
        </div>
      </div>
      {scoringQuestions.map((question, index) => (
        <div key={index} className='row'>
          <ScoringQuestionRow
            eventId={eventId}
            question={question}
            index={index}
            onEditSuccess={getAllScoringQuestionsOfEvent}
            onDeleteSuccess={getAllScoringQuestionsOfEvent}
          />
        </div>
      ))}
    </>
  );
};

export default AllScoringQuestions;
