import { useState } from 'react';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { Button, Grid, TextField } from '@mui/material';

const CreateEvent = ({ eventId, onSuccess, closeModal }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post('/api/events/create', { name, description });
      setLoading(false);
      toast.success('Event created successfully');
      onSuccess();
      closeModal();
    } catch (error) {
      console.log(error.response.data.error_message);
      toast.error(error.response.data.error_message);
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={submitHandler} className='padding1 margin1'>
        <Grid container spacing={3} direction='column' textAlign={'center'}>
          <h1>Create Event</h1>
          <Grid item>
            <TextField id='name' name='name' label='name' type='text' value={name} required fullWidth onChange={(e) => setName(e.target.value)} />
          </Grid>
          <Grid item>
            <TextField
              id='description'
              description='description'
              label='description'
              type='description'
              value={description}
              required
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Grid item className='buttons-container'>
            <Button onClick={closeModal}>Cancel</Button>
            <LoadingButton loading={loading} type='submit' variant='contained'>
              Create
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CreateEvent;
