import React, { useState } from 'react';
import { Button } from '@mui/material';
import CreateGroup from './CreateGroup';
import MyModal from '../ui/MyModal';

const AddGroupButton = ({ eventId, onSuccess }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <MyModal isModalOpen={openModal} closeModal={() => setOpenModal(false)}>
        <CreateGroup eventId={eventId} onSuccess={onSuccess} closeModal={() => setOpenModal(false)} />
      </MyModal>
      <Button variant='contained' color='success' onClick={() => setOpenModal(true)} size='small'>
        Add Group
      </Button>
    </>
  );
};

export default AddGroupButton;
