import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const DeleteEventButton = ({ eventId, onDeleteSuccess }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const navigate = useNavigate();

  const deleteEvent = async () => {
    setIsRequestLoading(true);
    try {
      await axios.delete(`/api/events/delete/${eventId}`);
      toast.success('Event deleted successfully');
      onDeleteSuccess();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  if (!eventId) return 'No event id provided';
  return (
    <>
      <Button onClick={() => setConfirmationOpen(true)} variant='contained' color='error'>
        Delete event
      </Button>
      <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
        <DialogTitle>Delete event</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this event?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationOpen(false)}>Cancel</Button>

          <LoadingButton onClick={deleteEvent} variant='contained' color='error' loading={isRequestLoading}>
            Delete event
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteEventButton;
