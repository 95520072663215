import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Chip, Divider, Grid, TextField } from '@mui/material';
import AllScoringQuestions from '../scoring-questions/AllScoringQuestions';
import AllPosters from '../poster/AllPosters';
import AllGroups from '../group/AllGroups';
import AllJudges from '../judge/AllJudges';
import DeleteEventButton from './DeleteEventButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import SettingsIcon from '@mui/icons-material/Settings';
import QuizIcon from '@mui/icons-material/Quiz';
import Groups2Icon from '@mui/icons-material/Groups2';
import GavelIcon from '@mui/icons-material/Gavel';
import ArticleIcon from '@mui/icons-material/Article';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import EventDetails from './EventDetails';

const EventPage = () => {
  const { eventId } = useParams();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setValue(+queryParams.get('tab') ?? 0);
  }, []);

  const ActiveTab = () => {
    switch (value) {
      case 0:
        return <EventDetails eventId={eventId} />;
      case 1:
        return <AllScoringQuestions eventId={eventId} />;
      case 2:
        return <AllJudges eventId={eventId} />;
      case 3:
        return <AllGroups eventId={eventId} />;
      case 4:
        return <AllPosters eventId={eventId} />;
      case 5:
        return (
          <div className='buttons-container padding1'>
            <Link to={`/client-view/${eventId}`}>
              <Button variant='contained'>Client view</Button>
            </Link>
          </div>
        );
      default:
        return <AllPosters eventId={eventId} />;
    }
  };

  return (
    <div className='my-width-100vw'>
      <div className='buttons-container padding1'>
        <Link to='/events'>
          <Button>Back to Events</Button>
        </Link>
      </div>
      <Tabs
        className='shadowed'
        style={{ borderRadius: '25px' }}
        value={value}
        onChange={handleChange}
        aria-label='icon position tabs example'
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab icon={<SettingsIcon />} iconPosition='start' label='Event Details' />
        <Tab icon={<QuizIcon />} iconPosition='start' label='Scoring Questions' />
        <Tab icon={<GavelIcon />} iconPosition='start' label='Judges' />
        <Tab icon={<Groups2Icon />} iconPosition='start' label='Groups' />
        <Tab icon={<ArticleIcon />} iconPosition='start' label='Posters' />
        <Tab icon={<ReadMoreIcon />} iconPosition='start' label='Actions' />
      </Tabs>
      <ActiveTab />
    </div>
  );
};

export default EventPage;
