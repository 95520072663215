import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logoN from './../../assets/images/logo-n.png';
import { Button } from '@mui/material';

const TopBarForIndividualCustomerStories = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='bg-[#FDFDFD] flex justify-center items-center p-2 w-[100%] h-[fit-content]' style={{ boxShadow: '0px 5px 10px #87B52D40' }}>
      <div className='flex w-full max-w-screen-lg items-center'>
        <div className={`flex-1 text-center ${isMobile && 'flex justify-center'}`}>
          <Link to={'/'} className='flex items-center'>
            <img src={logoN} className='h-16 mr-3 sm:h-16 ' alt='InstaJudge Logo' />
          </Link>
        </div>
        {!isMobile && (
          <>
            <div className='flex-1 flex justify-center items-center'>
              <div className='font-montserrat font-bold text-[28px] text-center text-[#84c041] opacity-100'>Stories</div>
            </div>
            <div className='flex-1 text-right'>
              <Link to={'/customer-stories'}>
                <Button>Back to stories</Button>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TopBarForIndividualCustomerStories;
