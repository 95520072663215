import React from 'react';
import TopBarForCustomerStoriesPage from '../../components/ui/TopBarForCustomerStoriesPage';
import { Link } from 'react-router-dom';
import georgePic from '../../assets/images/george.png';
import katerinaPic from '../../assets/images/katerina.png';
import photo1 from './best-poster-in-boston/assets/1.jpg';
import photo2 from './eicc-2024-audience-engaged/assets/1.jpg';
import photo4 from './taste-testing-gelato-on-a-greek-island/assets/4.jpg';

const CustomerStories = () => {
  const posts = [
    {
      category: 'ePoster Competition',
      title: 'Best Poster in Boston',
      description:
        'The Plastic Surgery Research Council (PSRC) is a high-level organization focused on stimulating fundamental research in plastic surgery and promoting excellence in clinical innovation and clinical translation.',
      author: 'George Chaitides',
      date: 'May 16-19, 2024',
      readTime: '16 min read',
      imageUrl: photo1,
      authorImage: georgePic,
      url: '/customer-stories/best-poster-in-boston',
    },
    {
      category: 'Best Paper competition',
      title: 'Audience engaged!',
      description:
        'EICC 2024 encourages dialogue between computer scientists and researchers in other fields related to cybersecurity such as behavioral sciences, sociology, criminology, investigations and law.',
      author: 'Katerina Pavlides',
      date: 'June 5-6, 2024',
      readTime: '16 min read',
      imageUrl: photo2,
      authorImage: katerinaPic,
      url: '/customer-stories/eicc-2024-audience-engaged',
    },
    {
      category: 'Taste Testing Gelato on a Greek Island',
      title: 'The Best Flavor goes to…',
      description:
        'Lolita’s Gelateria lives on the picturesque cliffs of Santorini, Greece. This summer, on July 20 and 21, they hosted a special Chocolate Event where they debuted new flavors. Over the course of two hot summer evenings, 19 flavors of gelato were taste tested by 70 people who submitted 161 votes. Using the InstaJudge platform, patrons scanned the QR code displayed throughout the store and were able to leave ratings on each flavor.',
      author: 'Katerina Pavlides',
      date: 'July 20-21, 2024',
      readTime: '12 min read',
      imageUrl: photo4,
      authorImage: katerinaPic,
      url: '/customer-stories/taste-testing-gelato-on-a-greek-island',
    },
  ];

  return (
    <div className='container-with-bg'>
      <TopBarForCustomerStoriesPage />
      <div className='max-w-4xl mx-auto p-4 sm:px-6 lg:px-8'>
        <div className='grid gap-8 grid-cols-3'>
          {posts.map((post, index) => (
            <div key={index} className='basic-glass rounded-lg max-w-[400px] overflow-hidden'>
              <img src={post.imageUrl} alt={post.title} className='w-full h-48 object-cover' />
              <div className='p-6'>
                <span className='text-mint-deep-green text-xs uppercase tracking-wider'>{post.category}</span>
                <h3 className='text-xl font-semibold mt-2'>{post.title}</h3>
                <p className='mt-3 text-gray-400'>{post.description}</p>
                <div className='mt-4 flex items-center'>
                  <img src={post.authorImage} alt={post.author} className='w-10 h-10 aspect-auto rounded-full' />
                  <div className='ml-3'>
                    <p className='text-sm font-medium'>{post.author}</p>
                    <div className='flex space-x-1 text-sm text-gray-400'>
                      <time>{post.date}</time>
                      {/* <span>&middot;</span> */}
                      {/* <span>{post.readTime}</span> */}
                    </div>
                  </div>
                  <Link to={`${post.url}`} className='mt-4 block text-mint-deep-green hover:underline ml-auto'>
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomerStories;
