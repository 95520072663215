import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
// import JudgeMint from './assets/images/logo4.png';
import JudgeMint from './assets/images/mascot1.JPG';
import { useEventContext } from './context/EventContext';
import TopBarForStartJudgingPage from './components/ui/TopBarForStartJudgingPage';
import { Button } from '@mui/material';

const StartJudgingGroup = () => {
  const navigate = useNavigate();
  const { setActiveEventId, name, description } = useEventContext();
  const { groupId, eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [groupsOfJudge, setGroupsOfJudge] = useState(JSON.parse(localStorage.getItem(`${eventId}-groupsOfJudge`)) || []);

  const judgeId = localStorage.getItem(`${eventId}-judgeId`);
  const judgeHasScoredThisGroup = groupsOfJudge.includes(groupId);

  useEffect(() => {
    setActiveEventId(eventId);
  }, [eventId]);

  const createAnonymousJudge = async () => {
    if (judgeId) {
      return {
        _id: judgeId,
        name: localStorage.getItem(`${eventId}-judgeName`),
        email: localStorage.getItem(`${eventId}-judgeEmail`),
      };
    }

    try {
      const timestamp = new Date().getTime();
      const name = `Anonymous ${timestamp}`;
      const email = `anonymous${timestamp}@instajudge.com`;
      const { data } = await axios.post(`/api/judges/create/${eventId}`, { name, email });
      localStorage.setItem(`${eventId}-judgeId`, data._id);
      localStorage.setItem(`${eventId}-judgeName`, data.name);
      localStorage.setItem(`${eventId}-judgeEmail`, data.email);

      return {
        _id: data._id,
        name: data.name,
        email: data.email,
      };
    } catch (error) {
      console.log(error.response.data.error_message);
    }
  };

  const addJudgeToGroup = async (judge) => {
    if (judgeHasScoredThisGroup) {
      return;
    }

    try {
      const { data } = await axios.post(`/api/groups/add-judges-to-group/${groupId}`, { newJudges: [judge] });
      setGroupsOfJudge((prevGroups) => {
        const updatedGroups = [...prevGroups, groupId];
        return updatedGroups;
      });
      const updatedGroups = [...groupsOfJudge, groupId];
      localStorage.setItem(`${eventId}-groupsOfJudge`, JSON.stringify(updatedGroups));
    } catch (error) {
      console.log(error);
    }
  };

  const createJudgeAndAttachToGroup = async () => {
    // const toastId = toast.loading('Creating judge...');
    setLoading(true);
    try {
      const judge = await createAnonymousJudge();
      await addJudgeToGroup(judge);
      navigate(`/judge-view/${judge._id}/${groupId}`);
      // toast.dismiss(toastId); // Close the specific "Creating judge..." toast
    } catch (error) {
      // toast.update(toastId, { render: 'Error occurred. Please try again.', type: 'error', isLoading: false, autoClose: 5000 });
      console.error('Error occurred:', error);
    } finally {
      setLoading(false);
    }
  };

  // DEV
  const deletePageDataFromLocalStorage = () => {
    localStorage.removeItem(`${eventId}-judgeId`);
    localStorage.removeItem(`${eventId}-judgeName`);
    localStorage.removeItem(`${eventId}-judgeEmail`);
    localStorage.removeItem(`${eventId}-groupsOfJudge`);
    window.location.reload();
  };

  return (
    <div className='container-with-bg'>
      <TopBarForStartJudgingPage eventId={eventId} />
      <div
        className='max-w-lg rounded  shadow-lg bg-white basic-glass mt-4 text-center md:px-4'
        // style={{ minHeight: '400px', height: 'fit-content!important' }}
      >
        {/* <img className='w-full bg-image' src={JudgeMint} alt='Judge Mint' /> */}
        <div className='margin-block-1'>
          <div className='font-montserrat font-bold text-[28px] text-center text-mint-deep-green opacity-100 mb-4'>{name}</div>
          <div className='font-montserrat font-semibold text-[16px] text-center text-[#828274] opacity-100'>{description}</div>
        </div>
        <div className='px-6'>
          {/* <div className='font-bold text-xl mb-2 text-mint-deep-green'>Hello, I am Judge Mint.</div> */}
          {/* <p className='text-gray-700 text-base'>{judgeId ? 'Welcome back!' : 'Is this your first time?'}</p> */}
        </div>
        <div className='px-6 py-4 flex justify-center'>
          <LoadingButton variant='contained' color='secondary' className='p-4' onClick={createJudgeAndAttachToGroup} loading={loading}>
            {judgeId ? 'Continue judging' : 'Start judging'}
          </LoadingButton>
        </div>
      </div>

      {/* <Button variant='contained' color='secondary' onClick={deletePageDataFromLocalStorage}>
        Delete page data from local storage
      </Button> */}
    </div>
  );
};

export default StartJudgingGroup;
