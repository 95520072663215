import { useEffect, useState } from 'react';
import { Chip, Divider, FormLabel, StepLabel, TextField } from '@mui/material';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const EventLabels = ({ eventId }) => {
  const [posterSingularLabel, setPosterSingularLabel] = useState('');
  const [posterPluralLabel, setPosterPluralLabel] = useState('');
  const [groupSingularLabel, setGroupSingularLabel] = useState('');
  const [groupPluralLabel, setGroupPluralLabel] = useState('');
  const [judgeSingularLabel, setJudgeSingularLabel] = useState('');
  const [judgePluralLabel, setJudgePluralLabel] = useState('');
  const [scoringQuestionSingularLabel, setScoringQuestionSingularLabel] = useState('');
  const [scoringQuestionPluralLabel, setScoringQuestionPluralLabel] = useState('');
  const [presenterSingularLabel, setPresenterSingularLabel] = useState('');
  const [presenterPluralLabel, setPresenterPluralLabel] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const getEventLabels = async () => {
    try {
      const { data } = await axios.get(`/api/events/event-labels/${eventId}`);
      setPosterSingularLabel(data.posterSingularLabel);
      setPosterPluralLabel(data.posterPluralLabel);
      setGroupSingularLabel(data.groupSingularLabel);
      setGroupPluralLabel(data.groupPluralLabel);
      setJudgeSingularLabel(data.judgeSingularLabel);
      setJudgePluralLabel(data.judgePluralLabel);
      setScoringQuestionSingularLabel(data.scoringQuestionSingularLabel);
      setScoringQuestionPluralLabel(data.scoringQuestionPluralLabel);
      setPresenterSingularLabel(data.presenterSingularLabel);
      setPresenterPluralLabel(data.presenterPluralLabel);
    } catch (error) {
      console.log(error.response.data.error_message);
      toast.error(error.response.data.error_message);
    }
    setIsLoading(false);
  };

  const updateEventLabels = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);
    try {
      const { data } = await axios.put(`/api/events/event-labels/${eventId}`, {
        posterSingularLabel,
        posterPluralLabel,
        groupSingularLabel,
        groupPluralLabel,
        judgeSingularLabel,
        judgePluralLabel,
        scoringQuestionSingularLabel,
        scoringQuestionPluralLabel,
        presenterSingularLabel,
        presenterPluralLabel,
      });
      toast.success('Event labels updated successfully');
    } catch (error) {
      console.log(error.response.data.error_message);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  useEffect(() => {
    getEventLabels();
  }, [eventId]);

  return (
    <>
      <Divider component='div' role='presentation'>
        <Chip
          label={<div className='row'>Event labels</div>}
          style={{ marginBlock: '1em', background: 'var(--primary)', minWidth: '150px', color: '#fff' }}
        />
      </Divider>
      <form onSubmit={updateEventLabels} style={{ marginBlockEnd: '3em' }}>
        <Grid container spacing={2} direction='column' textAlign={'center'}>
          <h3>Poster</h3>
          <Grid item container spacing={2} direction='row' justifyContent={'space-evenly'} alignItems={'center'}>
            <Grid item>
              <TextField
                id='poster-singular'
                name='poster-singular'
                label='poster-singular'
                type='text'
                value={posterSingularLabel}
                required
                fullWidth
                onChange={(e) => setPosterSingularLabel(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                id='poster-plural'
                name='poster-plural'
                label='poster-plural'
                type='text'
                value={posterPluralLabel}
                required
                fullWidth
                onChange={(e) => setPosterPluralLabel(e.target.value)}
              />
            </Grid>
          </Grid>
          <h3>Group</h3>
          <Grid item container spacing={2} direction='row' justifyContent={'space-evenly'} alignItems={'center'}>
            <Grid item>
              <TextField
                id='group-singular'
                name='group-singular'
                label='group-singular'
                type='text'
                value={groupSingularLabel}
                required
                fullWidth
                onChange={(e) => setGroupSingularLabel(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                id='group-plural'
                name='group-plural'
                label='group-plural'
                type='text'
                value={groupPluralLabel}
                required
                fullWidth
                onChange={(e) => setGroupPluralLabel(e.target.value)}
              />
            </Grid>
          </Grid>
          <h3>Judge</h3>
          <Grid item container spacing={2} direction='row' justifyContent={'space-evenly'} alignItems={'center'}>
            <Grid item>
              <TextField
                id='judge-singular'
                name='judge-singular'
                label='judge-singular'
                type='text'
                value={judgeSingularLabel}
                required
                fullWidth
                onChange={(e) => setJudgeSingularLabel(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                id='judge-plural'
                name='judge-plural'
                label='judge-plural'
                type='text'
                value={judgePluralLabel}
                required
                fullWidth
                onChange={(e) => setJudgePluralLabel(e.target.value)}
              />
            </Grid>
          </Grid>
          <h3>Scoring question</h3>
          <Grid item container spacing={2} direction='row' justifyContent={'space-evenly'} alignItems={'center'}>
            <Grid item>
              <TextField
                id='scoringQuestion-singular'
                name='scoringQuestion-singular'
                label='scoringQuestion-singular'
                type='text'
                value={scoringQuestionSingularLabel}
                required
                fullWidth
                onChange={(e) => setScoringQuestionSingularLabel(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                id='scoringQuestion-plural'
                name='scoringQuestion-plural'
                label='scoringQuestion-plural'
                type='text'
                value={scoringQuestionPluralLabel}
                required
                fullWidth
                onChange={(e) => setScoringQuestionPluralLabel(e.target.value)}
              />
            </Grid>
          </Grid>
          <h3>Presenter</h3>
          <Grid item container spacing={2} direction='row' justifyContent={'space-evenly'} alignItems={'center'}>
            <Grid item>
              <TextField
                id='presenter-singular'
                name='presenter-singular'
                label='presenter-singular'
                type='text'
                value={presenterSingularLabel}
                required
                fullWidth
                onChange={(e) => setPresenterSingularLabel(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                id='presenter-plural'
                name='presenter-plural'
                label='presenter-plural'
                type='text'
                value={presenterPluralLabel}
                required
                fullWidth
                onChange={(e) => setPresenterPluralLabel(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item textAlign={'center'} justifyContent={'space-evenly'} display='flex'>
          <LoadingButton loading={isRequestLoading} color='primary' type='submit' variant='contained' size='small'>
            Save labels
          </LoadingButton>
        </Grid>
      </form>
    </>
  );
};

export default EventLabels;
