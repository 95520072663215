import React from 'react';
import GroupQRComponent from '../group/GroupQRComponent';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const JudgeNowComponentWithQR = ({ groupId, eventId }) => {
  return (
    <div className='flex p-5 mb-8 judge-now-container'>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div className='font-montserrat font-bold text-[24px] text-left text-[#87B52D] opacity-100'>Judge now</div>
        <div className='text-[#707070] max-w-[70%] font-semibold text-sm sm:text-base'>
          Submit your entry and judge the competition easily by scanning the QR code with your mobile device or clicking the button.
        </div>
        <div>
          <span className='text-[#707070] max-w-[70%] font-semibold text-sm sm:text-base'>Can not scan right now? </span>
          <Link to={`/start-judging-group/${eventId}/${groupId}`}>
            <Button>Click here</Button>
          </Link>
        </div>
      </div>
      <div className='flex flex-col justify-center'>
        <GroupQRComponent groupId={groupId} eventId={eventId} />
      </div>
    </div>
  );
};

export default JudgeNowComponentWithQR;
