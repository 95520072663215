import React from 'react';
import classes from './Modal.module.css';
import { IconButton } from '@mui/material';

const MyModal = ({ children, closeModal, isModalOpen }) => {
  return (
    <>
      {isModalOpen ? (
        <div>
          <div className={classes.backdrop} />
          <div className={classes.modal}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MyModal;
