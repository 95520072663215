import { LoadingButton } from '@mui/lab';
import { Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useEventContext } from '../../context/EventContext';
import SettingsShowPresenters from './SettingsShowPresenters';
import SettingsShowGroupNames from './SettingsShowGroupNames';

const EventSettings = ({ eventId }) => {
  const {
    showGoToLeaderboardButton: showGoToLeaderboardButtonFromContext,
    setShowGoToLeaderboardButton: setShowGoToLeaderboardButtonFromContext,
    setActiveEventId,
  } = useEventContext();

  const [showGoToLeaderboardButton, setShowGoToLeaderboardButton] = useState(showGoToLeaderboardButtonFromContext);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  useEffect(() => {
    setActiveEventId(eventId);
  }, [eventId]);

  const updateShowGoToLeaderboardButton = async () => {
    setIsRequestLoading(true);
    try {
      const { data } = await axios.put(`/api/events/show-go-to-leaderboard-button/${eventId}`, { showGoToLeaderboardButton });
      toast.success('Scoring mode updated successfully');
      setShowGoToLeaderboardButtonFromContext(showGoToLeaderboardButton);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  const isButtonDisabled = showGoToLeaderboardButton === showGoToLeaderboardButtonFromContext;

  return (
    <>
      <Grid container spacing={2} direction='column' textAlign={'center'}>
        <div style={{ padding: '1em' }}>
          <Divider component='div' role='presentation'>
            <Chip
              label={<div className='row'>Appearence</div>}
              style={{ marginBlock: '1em', background: 'var(--primary)', minWidth: '150px', color: '#fff' }}
            />
          </Divider>
          <div className='flex p-4 justify-center items-center'>
            <FormGroup>
              <FormControlLabel
                sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}
                label={'Show "Go to Leaderboard" Button ' + (showGoToLeaderboardButton ? '(Now visible)' : '(Now hidden)')}
                control={<Checkbox checked={showGoToLeaderboardButton} onChange={(e) => setShowGoToLeaderboardButton(e.target.checked)} />}
              />
            </FormGroup>
            <LoadingButton
              disabled={isButtonDisabled}
              loading={isRequestLoading}
              variant='contained'
              color='primary'
              onClick={updateShowGoToLeaderboardButton}
              size='small'
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </Grid>
      <SettingsShowPresenters eventId={eventId} />
      <SettingsShowGroupNames eventId={eventId} />
    </>
  );
};

export default EventSettings;
