import React, { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';

const PosterRow = ({ poster, onSuccessfulDelete = () => console.log('No onSuccessfulDelete was provided') }) => {
  const [isDeletePosterRequestLoading, setIsDeletePosterRequestLoading] = useState(false);

  const deletePoster = async () => {
    setIsDeletePosterRequestLoading(true);
    try {
      await axios.delete(`/api/posters/delete/${poster._id}`);
      toast.success('Poster deleted');
      onSuccessfulDelete();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsDeletePosterRequestLoading(false);
  };

  return (
    <div className='row padding1'>
      <p>{poster.index}</p>
      <p
        className='my-width-350'
        dangerouslySetInnerHTML={{
          __html: `Title: ${poster.title}`,
        }}
      ></p>
      <p>Presenter: {poster.presenterName}</p>
      {/* <p>{poster.presenterEmail}</p> */}
      <p>Group:{poster.group?.name}</p>
      {!poster.group && (
        <Tooltip title='This poster has no group assigned to it' placement='right' arrow>
          <WarningIcon color='error' />
        </Tooltip>
      )}
      <div className='actions-container'>
        <Link to={`/posters/${poster._id}`}>
          <Button variant='contained' size='small' color='warning'>
            Edit poster
          </Button>
        </Link>
        <LoadingButton loading={isDeletePosterRequestLoading} variant='contained' size='small' color='error' onClick={deletePoster}>
          Delete poster
        </LoadingButton>
      </div>
    </div>
  );
};

export default PosterRow;
