import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
export default function ScoringProgressBar({ completed, total }) {
  const progress = (completed / total) * 100;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant='determinate' value={progress} />
      </Box>
      <Box sx={{ minWidth: 35, textAlign: 'right' }}>
        <Typography sx={{ fontSize: '16px' }}>{`${completed}/${total}`}</Typography>
      </Box>
    </Box>
  );
}
