import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import MuiCustomTheme from './theme/MuiCustomTheme';
import CreateEvent from './components/event/CreateEvent';
import HomeScreen from './HomeScreen';
import LoginComponent from './components/login/LoginComponent';
import SignUpComponent from './components/login/SignUpComponent';
import AuthenticationProvider from './context/AuthenticationContext';
import { InternalToaster } from './components/ui/InternalToaster';
import AllEvents from './components/event/AllEvents';
import EventPage from './components/event/EventPage';
import ImportPostersToEvent from './components/event/ImportPostersToEvent';
import GroupPage from './components/group/GroupPage';
import EditJudge from './components/judge/EditJudge';
import EditPoster from './components/poster/EditPoster';
import JudgeView from './components/judge-view/JudgeView';
import ButtonAppBar from './components/ui/ButtonAppBar';
import ClientPage from './components/client-view/ClientPage';
import { NonAuthRoute, ProtectedRoute } from './components/auth/AuthRoutes';
import PrintQRCodesForJudgesPage from './components/judge/PrintQRCodesForJudgesPage';
import LandingPageScreen from './LandingPageScreen';
import EventProvider from './context/EventContext';
import StartJudgingGroup from './StartJudgingGroup';
import DemoScreen from './DemoScreen';
// import ContactsScreen from './screens/ContactsScreen';

import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import CustomerStories from './screens/customer-stories/CustomerStories';
import BestPosterInBoston from './screens/customer-stories/best-poster-in-boston/BestPosterInBoston';
import EICC2024AudienceEngaged from './screens/customer-stories/eicc-2024-audience-engaged/EICC2024AudienceEngaged';
import StartNow from './screens/start-now/StartNow';
import LolitaTasteTestingGelato from './screens/customer-stories/taste-testing-gelato-on-a-greek-island/LolitaTasteTestingGelato';
const TRACKING_ID = 'G-TFLSSW58K3';

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: 'pageview', page: '/', title: 'InstaJudge Login Page' });
    console.log('GA initialized with tracking id: ', TRACKING_ID);
  }, []);

  return (
    <MuiCustomTheme>
      <InternalToaster />
      <Router>
        <AuthenticationProvider>
          <ButtonAppBar />
          <EventProvider>
            <Routes>
              <Route
                path='/'
                exact
                element={
                  <NonAuthRoute>
                    <LandingPageScreen />
                  </NonAuthRoute>
                }
              />
              <Route
                path='/customer-stories'
                exact
                element={
                  <NonAuthRoute>
                    <CustomerStories />
                  </NonAuthRoute>
                }
              />
              <Route
                path='/customer-stories/best-poster-in-boston'
                exact
                element={
                  <NonAuthRoute>
                    <BestPosterInBoston />
                  </NonAuthRoute>
                }
              />
              <Route
                path='/customer-stories/eicc-2024-audience-engaged'
                exact
                element={
                  <NonAuthRoute>
                    <EICC2024AudienceEngaged />
                  </NonAuthRoute>
                }
              />
              <Route
                path='/customer-stories/taste-testing-gelato-on-a-greek-island'
                exact
                element={
                  <NonAuthRoute>
                    <LolitaTasteTestingGelato />
                  </NonAuthRoute>
                }
              />
              <Route
                path='/start-now'
                exact
                element={
                  <NonAuthRoute>
                    <StartNow />
                  </NonAuthRoute>
                }
              />
              <Route
                path='/login'
                element={
                  <NonAuthRoute>
                    <LoginComponent />
                  </NonAuthRoute>
                }
              />
              <Route
                path='/register'
                element={
                  <NonAuthRoute>
                    <SignUpComponent />
                  </NonAuthRoute>
                }
              />
              <Route
                path='/events/create'
                element={
                  <ProtectedRoute>
                    <CreateEvent />
                  </ProtectedRoute>
                }
              />
              <Route path='/events/import/:eventId' element={<ImportPostersToEvent />} />
              <Route path='/events/print-qr-codes-for-judges/:eventId' element={<PrintQRCodesForJudgesPage />} />
              <Route
                path='/events'
                element={
                  <ProtectedRoute>
                    <AllEvents />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/events/:eventId'
                element={
                  <ProtectedRoute>
                    <EventPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/groups/:groupId'
                element={
                  <ProtectedRoute>
                    <GroupPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/judges/:judgeId'
                element={
                  <ProtectedRoute>
                    <EditJudge />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/posters/:posterId'
                element={
                  <ProtectedRoute>
                    <EditPoster />
                  </ProtectedRoute>
                }
              />
              {/* TODO: delete functionality */}
              {/* <Route
                path='/contacts'
                element={
                  <ProtectedRoute>
                    <ContactsScreen />
                  </ProtectedRoute>
                }
              /> */}
              <Route path='/start-judging-group/:eventId/:groupId' element={<StartJudgingGroup />} />
              <Route path='/judge-view/:judgeId/:groupId' element={<JudgeView />} />
              <Route path='/client-view/:eventId' element={<ClientPage />} />
              <Route path='/demo' element={<DemoScreen />} />
              <Route path='/*' element={<>404</>} />
            </Routes>
          </EventProvider>
        </AuthenticationProvider>
      </Router>
    </MuiCustomTheme>
  );
}

export default App;
