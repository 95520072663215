import React from 'react';

const H1 = ({ children, className }) => {
  return <div className={'text-3xl' + ' ' + className}>{children}</div>;
};

const H2 = ({ children, className }) => {
  return <div className={'text-2xl' + ' ' + className}>{children}</div>;
};

const H3 = ({ children, className }) => {
  return <div className={'text-xl' + ' ' + className}>{children}</div>;
};

const H4 = ({ children, className }) => {
  return <div className={'text-lg' + ' ' + className}>{children}</div>;
};

const H5 = ({ children, className }) => {
  return <div className={'text-base' + ' ' + className}>{children}</div>;
};

const H6 = ({ children, className }) => {
  return <div className={'text-sm' + ' ' + className}>{children}</div>;
};

export { H1, H2, H3, H4, H5, H6 };
