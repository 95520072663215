import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import ScoreSlider from './ScoreSlider';
import axios from 'axios';
import { CheckmarkIcon, toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Chip } from '@mui/material';
import { H2, H3 } from '../ui/HTags';
import { useEventContext } from '../../context/EventContext';

const PosterJudgementCard = ({ index, poster, scoringQuestions = [], judgeId, onAddScore, score, scoringQueestionsLabel }) => {
  const { showPresenters, showGroupNames } = useEventContext();

  const [answersOfJudge, setAnswersOfJudge] = useState([]);
  const [posterHasBeenScoredByJudge, setPosterHasBeenScoredByJudge] = useState(false);
  const [saveNewScoreIsEnabled, setSaveNewScoreIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [fullView, setFullView] = useState(false);

  const getScoreOfPosterForThisJudge = async () => {
    try {
      const { data } = await axios.get(`/api/scores/get-score-of-poster-by-judge/${judgeId}/${poster._id}`);
      if (data.answers.length > 0) {
        setAnswersOfJudge([...data.answers]);
        setPosterHasBeenScoredByJudge(!!data.answers.length);
      } else {
        const newAnswersOfJudge = [];
        for (let i = 0; i < scoringQuestions.length; i++) {
          newAnswersOfJudge.push(0);
        }
        setAnswersOfJudge(newAnswersOfJudge);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getScoreOfPosterForThisJudge();
  }, []);

  const changePosterScoreAtIdx = (score, idx) => {
    const newAnswersOfJudge = [...answersOfJudge];
    newAnswersOfJudge[idx] = score;
    setAnswersOfJudge([...newAnswersOfJudge]);
    setSaveNewScoreIsEnabled(true);
  };

  const submitPosterScore = async () => {
    setIsSubmitLoading(true);
    try {
      const { data } = await axios.put(`/api/scores/update-score-of-poster/${score._id}`, {
        scores: answersOfJudge,
      });
      toast.success('Score submitted successfully');
      setPosterHasBeenScoredByJudge(true);
      setSaveNewScoreIsEnabled(false);
      setFullView(false);
      onAddScore(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsSubmitLoading(false);
  };

  const updatePosterScore = async () => {
    setIsSubmitLoading(true);
    try {
      const { data } = await axios.put(`/api/scores/update-score-of-poster/${score._id}`, {
        scores: answersOfJudge,
      });
      toast.success('Score updated successfully');
      setSaveNewScoreIsEnabled(false);
      setFullView(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsSubmitLoading(false);
  };

  const clearScore = async () => {
    setIsSubmitLoading(true);
    try {
      const { data } = await axios.put(`/api/scores/update-score-of-poster/${score._id}`, {
        scores: [],
      });
      toast.success('Score cleared successfully');
      setAnswersOfJudge([...data.answers]);
      setSaveNewScoreIsEnabled(false);
      setPosterHasBeenScoredByJudge(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsSubmitLoading(false);
  };

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <div
      id={`poster-${index}`}
      className='scoring-card shadowed padding1'
      style={{
        border: '1px solid',
        borderRadius: '16px',
        borderColor: posterHasBeenScoredByJudge && '#036a00',
      }}
      onClick={() => {
        if (!fullView) setFullView(true);
      }}
    >
      {posterHasBeenScoredByJudge && <CheckmarkIcon />}
      <div className='row'>
        <H3>
          <div
            dangerouslySetInnerHTML={{
              __html: poster.title,
            }}
          ></div>
        </H3>
        <div className={`actions-container ${!posterHasBeenScoredByJudge && !fullView && 'animate-bounce'}`}>
          <Button onClick={() => setFullView(!fullView)}>{fullView ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Button>
        </div>
      </div>
      <div className='flex justify-between items-center'>
        {showPresenters && <h4>Presenter: {poster.presenterName}</h4>}
        {showGroupNames && (
          <div>
            <Chip
              style={{ background: 'var(--primary)', color: '#fff', height: 'fit-content', minHeight: '2.45em' }}
              label={<span style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>{poster.group.name}</span>}
            />
          </div>
        )}
      </div>
      {/* <h3>Avg: {Math.round((answersOfJudge.reduce((a, b) => a + b, 0) / scoringQuestions.length) * 100) / 100}/10</h3> */}
      {fullView && (
        <>
          {/* <h3 style={{ textTransform: 'capitalize' }}>{scoringQueestionsLabel}</h3> */}
          <div className='my-4'>
            {scoringQuestions.map((question, index) => (
              <div key={index} className='py-1'>
                <p>{question}</p>
                <ScoreSlider score={answersOfJudge[index]} setScoreOfQuestion={changePosterScoreAtIdx} indexOfQuestion={index} />
              </div>
            ))}
          </div>
          <div style={{ textAlign: 'center' }}>
            {!posterHasBeenScoredByJudge ? (
              <LoadingButton
                variant='contained'
                size='small'
                color='primary'
                onClick={submitPosterScore}
                loading={isSubmitLoading}
                disabled={answersOfJudge.includes(0)}
              >
                <a href={`#poster-${index + 1}`}>Score</a>
              </LoadingButton>
            ) : (
              <>
                <LoadingButton
                  variant='contained'
                  size='small'
                  color='warning'
                  disabled={answersOfJudge.some((score) => score === 0) || !saveNewScoreIsEnabled}
                  onClick={updatePosterScore}
                >
                  <a href={`#poster-${index + 1}`}>Save new score</a>
                </LoadingButton>
                {/* <LoadingButton variant='contained' size='small' color='error' onClick={clearScore}>
                  Clear score
                </LoadingButton> */}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PosterJudgementCard;
