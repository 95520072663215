import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { ScoreSlider5 } from './ScoreSlider';
import axios from 'axios';
import { CheckmarkIcon, toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Chip } from '@mui/material';
import { H2, H3 } from '../ui/HTags';

const DummyPosterJudgementCard = () => {
  const [answersOfJudge, setAnswersOfJudge] = useState([4, 5, 5]);
  const [posterHasBeenScoredByJudge, setPosterHasBeenScoredByJudge] = useState(false);
  const [fullView, setFullView] = useState(true);
  const [saveNewScoreIsEnabled, setSaveNewScoreIsEnabled] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const updatePosterScore = async () => {
    setIsSubmitLoading(true);
    setTimeout(() => {
      toast.success('Score submitted');
      setSaveNewScoreIsEnabled(false);
      setIsSubmitLoading(false);
    }, 400);
  };

  const changePosterScoreAtIdx = (score, idx) => {
    const newAnswersOfJudge = [...answersOfJudge];
    newAnswersOfJudge[idx] = score;
    setAnswersOfJudge([...newAnswersOfJudge]);
    setSaveNewScoreIsEnabled(true);
  };

  return (
    <div
      className='scoring-card shadowed my-4'
      style={{
        border: '1px solid',
        borderRadius: '16px',
        borderColor: posterHasBeenScoredByJudge && '#036a00',
        padding: '1rem 2rem',
      }}
    >
      {posterHasBeenScoredByJudge && <CheckmarkIcon />}
      <div className='row'>
        <H3>
          <div className='font-bold text-mint-deep-green'>Do you like InstaJudge?</div>
        </H3>
      </div>
      <div className='flex justify-between items-center'>
        <h4>Contestant: SciGen Technologies</h4>
        {/* <div>
          <Chip style={{ background: 'var(--primary)', color: '#fff' }} label={'Competiton 1'} />
        </div> */}
      </div>
      {/* <h3>Avg: {Math.round((answersOfJudge.reduce((a, b) => a + b, 0) / scoringQuestions.length) * 100) / 100}/10</h3> */}
      {fullView && (
        <>
          {/* <h3 style={{ textTransform: 'capitalize' }}>{scoringQueestionsLabel}</h3> */}
          <div className='my-4'>
            <div className='mb-8 py-1'>
              <p className='font-semibold'>How easy was it to find your way around InstaJudge?</p>
              <ScoreSlider5 score={answersOfJudge[0]} setScoreOfQuestion={changePosterScoreAtIdx} indexOfQuestion={0} />
              <div className='flex justify-between'>
                <p className='text-mint-deep-green text-left'>Needed a map</p>
                <p className='text-mint-deep-green text-right'>Like a pro navigator</p>
              </div>
            </div>
            <div className='mb-8 py-1'>
              <p className='font-semibold'>How relevant are InstaJudge’s features to you?</p>
              <ScoreSlider5 score={answersOfJudge[1]} setScoreOfQuestion={changePosterScoreAtIdx} indexOfQuestion={1} />
              <div className='flex justify-between'>
                <p className='text-mint-deep-green text-left'>Like a waterproof teabag</p>
                <p className='text-mint-deep-green text-right'>Can’t live without them!</p>
              </div>
            </div>
            <div className='mb-8 py-1'>
              <p className='font-semibold'>How would you rate your InstaJudge experience?</p>
              <ScoreSlider5
                score={answersOfJudge[2]}
                setScoreOfQuestion={changePosterScoreAtIdx}
                indexOfQuestion={2}
                startLabel={'a'}
                endLabel={'b'}
              />
              <div className='flex justify-between'>
                <p className='text-mint-deep-green text-left'>Horror movie</p>
                <p className='text-mint-deep-green text-right'>Best day ever!</p>
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            {!posterHasBeenScoredByJudge ? (
              <LoadingButton variant='contained' size='small' color='primary' onClick={updatePosterScore} loading={isSubmitLoading}>
                Score
              </LoadingButton>
            ) : (
              <>
                <LoadingButton
                  variant='contained'
                  size='small'
                  color='warning'
                  disabled={answersOfJudge.some((score) => score === 0)}
                  onClick={updatePosterScore}
                >
                  Save new score
                </LoadingButton>
                {/* <LoadingButton variant='contained' size='small' color='error' onClick={clearScore}>
                  Clear score
                </LoadingButton> */}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DummyPosterJudgementCard;
