import React from 'react';
import QRCode from 'react-qr-code';
import { H3, H4, H5 } from '../ui/HTags';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
const APP_URL = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : `${process.env.REACT_APP_LOCAL_SERVER_URL}:3000`;

const GroupQRComponent = ({ eventId, groupId }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        maxWidth: 180,
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <QRCode
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={`${APP_URL}/start-judging-group/${eventId}/${groupId}`}
        viewBox={`0 0 256 256`}
      />
    </div>
  );
};

export default GroupQRComponent;
