import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import JudgeQRCodeComponent from '../ui/JudgeQRCodeComponent';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const PrintQRCodesForJudgesPage = () => {
  const { eventId } = useParams();

  const [judges, setJudges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const downloadPDF = () => {
    const pdfContent = document.getElementById('pdf-content');

    // Temporarily hide non-PDF elements
    const originalDisplay = pdfContent.style.display;
    pdfContent.style.display = 'block';

    window.print();

    // Restore original display settings after printing
    pdfContent.style.display = originalDisplay;
  };

  const getAllJudgesOfEvent = async () => {
    try {
      const { data } = await axios.get(`/api/judges/get-judges-of-event/${eventId}`);
      setJudges(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllJudgesOfEvent();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className=' text-align-center'>
      <Link to={`/events/${eventId}`}>
        <Button size='small'>Back to event</Button>
      </Link>
      <h1>Print QR codes</h1>
      <div>
        <Button color='primary' variant='contained' size='small' onClick={downloadPDF}>
          Download PDF
        </Button>
      </div>
      <div className='col' id='pdf-content'>
        {judges.map((judge) => (
          <>
            <div className='page-break' />
            <h1> QR Code for judge</h1>
            <div key={judge._id}>
              <h2>{judge.name}</h2>
              <h4>{judge.email}</h4>
              <JudgeQRCodeComponent judgeId={judge._id} />
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default PrintQRCodesForJudgesPage;
