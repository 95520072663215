import React from 'react';
import * as XLSX from 'xlsx'; // Import all exports from 'xlsx'

import { Button } from '@mui/material';
const fileName = 'Import posters template';

// TODO: id show presenters is false, remove the presenter name and email from the template
const data = [
  {
    'Poster Index': '1',
    Title: 'Poster 1',
    'Presenter Name': 'Presenter 1',
    'Presenter Email': 'email@example.com',
    Group: 'Group 1',
  },
  {
    'Poster Index': '2',
    Title: 'Poster 2',
    'Presenter Name': 'Presenter 2',
    'Presenter Email': 'email2@example.com',
    Group: 'Group 2',
  },
  {
    'Poster Index': '3',
    Title: 'Poster 3',
    'Presenter Name': 'Presenter 3',
    'Presenter Email': 'email3@example.com',
    Group: 'Group 2',
  },
];
const DownloadImportPostersTemplate = ({}) => {
  const exportPosters = async () => {
    if (!data || data.length === 0) {
      console.warn('No data to export');
      return;
    }

    const columns = Object.keys(data[0]);
    const wsData = [columns];

    data.forEach((item) => {
      const rowData = columns.map((column) => item[column]);
      wsData.push(rowData);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet');

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return <Button onClick={exportPosters}>Download import template</Button>;
};

export default DownloadImportPostersTemplate;
