import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Button, IconButton } from '@mui/material';
import JudgesMultipleSelectAutoComplete from '../ui/JudgesMultipleSelectAutoComplete';
import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';

const GroupPage = () => {
  const [name, setName] = useState('');
  const [judgesOfGroup, setJudgesOfGroup] = useState([]);
  const [allJudges, setAllJudges] = useState([]);
  const [judgesToBeAddedToGroup, setJudgesToBeAddedToGroup] = useState([]);
  const [eventId, setEventId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [judgesLoading, setJudgesLoading] = useState(true);

  const [showEditGroupName, setShowEditGroupName] = useState(false);
  const [newName, setNewName] = useState('');
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const { groupId } = useParams();

  const getGroup = async () => {
    try {
      const { data } = await axios.get(`/api/groups/get-by-id/${groupId}`);
      setName(data.name);
      setNewName(data.name);
      setJudgesOfGroup(data.judges);
      setEventId(data.event._id);
      getAllJudgesOfEvent(data.event._id);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsLoading(false);
  };

  const getAllJudgesOfEvent = async (internalEventId) => {
    try {
      const { data } = await axios.get(`/api/judges/get-judges-of-event/${internalEventId}`);
      setAllJudges(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setJudgesLoading(false);
  };

  const addJudgesToGroup = async () => {
    try {
      const { data } = await axios.post(`/api/groups/add-judges-to-group/${groupId}`, { newJudges: judgesToBeAddedToGroup });
      console.log(data);
      toast.success('Judges added to group successfully');
      setJudgesOfGroup(data);
      setJudgesToBeAddedToGroup([]);
      getGroup();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
  };

  const removeJudgeFromGroup = async (judgeId) => {
    try {
      const { data } = await axios.delete(`/api/groups/remove-judge-from-group/${groupId}/${judgeId}`);
      toast.success('Judge removed from group successfully');
      setJudgesOfGroup(data);
      getGroup();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
  };

  const updateGroupName = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);
    try {
      await axios.put(`/api/groups/update-name/${groupId}`, { newName });
      toast.success('Name updated');
      setShowEditGroupName(false);
      setName(newName);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  const handleCancel = () => {
    setShowEditGroupName(false);
    setNewName(name);
  };

  useEffect(() => {
    getGroup();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Link to={`/events/${eventId}?tab=3`}>
        <Button>Back to event</Button>
      </Link>

      <h1>Group Page</h1>
      {showEditGroupName ? (
        <form onSubmit={updateGroupName}>
          <Grid container spacing={3} direction='column' textAlign={'center'}>
            <Grid item>
              <TextField
                id='name'
                name='name'
                label='name'
                type='text'
                value={newName}
                required
                fullWidth
                onChange={(e) => setNewName(e.target.value)}
              />
            </Grid>

            <Grid item textAlign={'center'} justifyContent={'space-evenly'} display='flex'>
              <Button loading={isRequestLoading} size='small' onClick={handleCancel}>
                Cancel
              </Button>
              <LoadingButton loading={isRequestLoading} color='primary' type='submit' variant='contained' size='small'>
                Save Name
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      ) : (
        <div className='row'>
          <h2>{name}</h2>
          <Button variant='contained' color='warning' onClick={() => setShowEditGroupName(true)} size='small'>
            Edit Group name
          </Button>
        </div>
      )}

      <h2>Judges of group</h2>
      {judgesOfGroup.length === 0 && <p>No judges</p>}
      {judgesOfGroup.map((judge) => {
        return (
          <div className='row'>
            <h3>{judge.name}</h3>
            <p>{judge.email}</p>

            <Button variant='contained' color='error' onClick={() => removeJudgeFromGroup(judge._id)} size='small'>
              Remove from group
            </Button>
          </div>
        );
      })}

      <h3>Add judges to group</h3>
      <JudgesMultipleSelectAutoComplete
        allJudges={allJudges}
        existingJudgesOfGroup={judgesOfGroup}
        judgesToBeAddedToGroup={judgesToBeAddedToGroup}
        setJudgesToBeAddedToGroup={setJudgesToBeAddedToGroup}
      />
      <Button variant='contained' color='primary' disabled={judgesToBeAddedToGroup.length === 0} onClick={addJudgesToGroup}>
        {judgesToBeAddedToGroup.length > 1 ? `Add judges to group` : 'Add judge to group'}
      </Button>
    </div>
  );
};

export default GroupPage;
