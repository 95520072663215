import { LoadingButton } from '@mui/lab';
import { Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useEventContext } from '../../context/EventContext';

const SettingsShowPresenters = ({ eventId }) => {
  const { showPresenters: showPresentersFromContext, setShowPresenters: setShowPresentersFromContext } = useEventContext();

  const [showPresenters, setShowPresenters] = useState(showPresentersFromContext);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const updateShowPresenters = async () => {
    setIsRequestLoading(true);
    try {
      const { data } = await axios.put(`/api/events/show-presenters/${eventId}`, { showPresenters });
      toast.success('Scoring mode updated successfully');
      setShowPresentersFromContext(showPresenters);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  const isButtonDisabled = showPresenters === showPresentersFromContext;

  return (
    <div className='flex p-4 justify-center items-center'>
      <FormGroup>
        <FormControlLabel
          sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}
          label={'Display presenters on judging view ' + (showPresenters ? '(Now visible)' : '(Now hidden)')}
          control={<Checkbox checked={showPresenters} onChange={(e) => setShowPresenters(e.target.checked)} />}
        />
        <Typography></Typography>
      </FormGroup>
      <div>
        <LoadingButton
          disabled={isButtonDisabled}
          loading={isRequestLoading}
          variant='contained'
          color='primary'
          onClick={updateShowPresenters}
          size='small'
        >
          Save
        </LoadingButton>
      </div>
    </div>
  );
};

export default SettingsShowPresenters;
