import React, { useState } from 'react';
import { Button } from '@mui/material';
import MyModal from '../ui/MyModal';
import CreateJudge from './CreateJudge';

const AddJudgeButton = ({ eventId, onSuccess }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <MyModal isModalOpen={openModal} closeModal={() => setOpenModal(false)}>
        <CreateJudge eventId={eventId} onSuccess={onSuccess} closeModal={() => setOpenModal(false)} />
      </MyModal>
      <Button variant='contained' color='success' onClick={() => setOpenModal(true)} size='small'>
        Add Judge
      </Button>
    </>
  );
};

export default AddJudgeButton;
