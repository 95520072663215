import React from 'react';
import image from './assets/1.jpg';
import TopBarForIndividualCustomerStories from '../../../components/ui/TopBarForIndividualCustomerStories';
import { Link } from 'react-router-dom';

const BestPosterInBoston = () => {
  return (
    <div className='container-with-bg'>
      <TopBarForIndividualCustomerStories />
      <div className='max-w-4xl mx-auto my-5 p-4 sm:px-6 lg:px-8 hero-section-glass-container'>
        {/* Title */}
        <h2 className='text-3xl font-bold text-center mb-5 text-mint-deep-green'>Best Poster in Boston</h2>
        {/* Content */}
        <p className='mb-3'>
          The Plastic Surgery Research Council (PSRC) is a high-level organization focused on stimulating fundamental research in plastic surgery and
          promoting excellence in clinical innovation and clinical translation.
        </p>
        <p className='mb-3'>
          The membership of the Research Council represents those in basic science, academic plastic surgery, clinical and translational research, and
          those at the forefront of innovation and tech development. Learn more about the Research Council, its programs, and members through{' '}
          <Link to={'https://ps-rc.org/'} target='_blank' className='text-mint-deep-green hover:underline'>
            its website
          </Link>
          .
        </p>
        <p className='mb-3'>Their 69th Annual Meeting took place at The Westin Copley Place, Boston, MA, on May 16-19, 2024.</p>
        <img src={image} alt='Best Poster in Boston' className=' w-full object-cover mb-3' />
        <p className='mb-3'>
          During the Annual Meeting, they held a unique poster competition. Participation was extensive and a specific team from the scientific
          committee was selected to judge the competition. InstaJudge was used to streamline the process of judging with automatic score submissions
          and instant progress monitoring. Judges were directed to scan a QR code which led them directly to the judging form.
        </p>
        <p className='mb-3'>
          As the results came in, organizers were able to review with judges and share the results in real time. The judges reported that they liked
          the QR codes and ability to score on their phones. All in all, at PSRC we saw a successful application of a technology so simple and
          accessible that it should be considered if you’re looking to add a wow factor to your next event.
        </p>
        <div className='mb-3 w-full flex justify-center'>
          <Link to={'/customer-stories'} className='text-mint-deep-green hover:underline'>
            Go back to stories
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BestPosterInBoston;
