import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { IconButton, TextField, Grid, InputAdornment, Button as MuiButton, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useAuthenticationContext } from './../../context/AuthenticationContext';

const EMAIL = process.env.REACT_APP_ENV === 'production' ? '' : 'tasos.stefanou@scigentech.com';
const PASS = process.env.REACT_APP_ENV === 'production' ? '' : 'tasos.stefanou@scigentech.com';

const LoginComponent = () => {
  const [email, setEmail] = useState(EMAIL);
  const [password, setPassword] = useState(PASS);
  const [showPassword, setShowPassword] = useState(false);

  const { login } = useAuthenticationContext();

  const submitHandler = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <Grid container spacing={3} direction='column'>
          <Grid item textAlign={'center'}>
            <Typography variant='modalTitle' className={'PoppinsRegular'}>
              Sign In
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id='email'
              name='email'
              label='Email'
              type='email'
              value={email}
              required
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              id='password'
              name='password'
              label='Password'
              type={showPassword ? 'text' : 'password'}
              required
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword((prev) => !prev)}>
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid display={'flex'} justifyContent='center' item>
            <LoadingButton variant='contained' type='submit' size='large' color='primary'>
              <Typography>Sign In</Typography>
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default LoginComponent;
