import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '@mui/material';

const MuiCustomTheme = ({ children }) => {
  const SCG_PRIMARY = '#4b8804';
  const SCG_SECONDARY = '#98D82A';
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          // tooltip: {
          //   fontSize: '0.8rem',
          //   color: 'white',
          //   backgroundColor: 'var(--primary)',
          // },
        },
      },
      MuiButton: {
        styleOverrides: {
          // Define styles for buttons with the secondary color
          containedSecondary: {
            color: 'white', // This sets the text color to white
          },
        },
      },
    },
    palette: {
      primary: {
        // main: colors.deepPurple['A200'],
        main: SCG_PRIMARY,
      },
      secondary: {
        // main: colors.lime['A400'],
        main: SCG_SECONDARY,
      },
      tetriary: {
        main: colors.blueGrey['50'],
      },
    },
    typography: {
      fontFamily: `'Poppins', sans-serif`,

      button: {
        textTransform: 'none',
      },
      adminSectionTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.5rem',
        fontWeight: 400,
        color: SCG_PRIMARY,
      },
      body1: {
        textTransform: 'none',
      },
      modalTitle: {
        color: SCG_PRIMARY,
        fontSize: '2.2rem',
      },
      h4: {
        textTransform: 'none',
      },
      h6: {
        textTransform: 'none',
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiCustomTheme;
