import React, { useState } from 'react';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const ScoringQuestionRow = ({ eventId, question, index, onEditSuccess, onDeleteSuccess }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [newQuestion, setNewQuestion] = useState(question);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const updateQuestionByIndex = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);
    try {
      const { data } = await axios.put(`/api/events/update-scoring-question/${eventId}`, { question: newQuestion, index });
      toast.success('Question updated successfully');
      setShowEdit(false);
      onEditSuccess();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  const deleteQuestionByIndex = async (index) => {
    setIsRequestLoading(true);
    try {
      const { data } = await axios.delete(`/api/events/delete-scoring-question/${eventId}/${index}`);
      toast.success('Question deleted successfully');
      setShowEdit(false);
      onDeleteSuccess();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  const handleCancel = () => {
    setNewQuestion(question);
    setShowEdit(false);
  };

  return (
    <>
      {showEdit ? (
        <form onSubmit={updateQuestionByIndex} style={{ margin: '1em' }}>
          <Grid container spacing={3} direction='row' textAlign={'center'}>
            <Grid item>
              <TextField
                multiline
                id='Question'
                name='Question'
                label='Question'
                type='text'
                value={newQuestion}
                required
                fullWidth
                onChange={(e) => setNewQuestion(e.target.value)}
              />
            </Grid>

            <Grid item textAlign={'center'} justifyContent={'space-evenly'} display='flex'>
              <Button loading={isRequestLoading} size='small' onClick={handleCancel}>
                Cancel
              </Button>
              <LoadingButton loading={isRequestLoading} color='warning' type='submit' variant='contained' size='small'>
                Save question
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      ) : (
        <>
          <h3>{`${index + 1}. ${question}`}</h3>
          <div className='actions-container'>
            <LoadingButton loading={isRequestLoading} variant='contained' color='warning' size='small' onClick={() => setShowEdit(true)}>
              Edit question
            </LoadingButton>
            <LoadingButton loading={isRequestLoading} variant='contained' color='error' size='small' onClick={() => deleteQuestionByIndex(index)}>
              Delete question
            </LoadingButton>
          </div>
        </>
      )}
    </>
  );
};

export default ScoringQuestionRow;
