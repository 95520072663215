// import tasosVideo from '../../assets/videos/tasosVideo2.mp4';
// import iphoneVideo from '../../assets/videos/iphoneVideo.mp4';
import LOGON from '../../assets/images/logo-n.png';

import classes from './IPhoneComponent.module.css';

document.addEventListener('DOMContentLoaded', function () {
  setTimeout(function () {
    document.getElementById('myVideo')?.play();
  }, 2000);
});

document.addEventListener('touchstart', function () {
  var video = document.getElementById('myVideo');
  if (video?.paused) {
    video?.play();
  }
});

const IPhoneComponent = () => {
  return (
    // <video
    //   src={iphoneVideo}
    //   style={{ width: '350px', background: 'white' }}
    //   id='myVideo'
    //   className='animate-logo'
    //   alt='section-image'
    //   autoPlay
    //   loop
    //   muted
    //   playsInline
    // />
    <div className={classes.card + ' ' + 'animate-logo'}>
      <div className={classes.btn1}></div>
      <div className={classes.btn2}></div>
      <div className={classes.btn3}></div>
      <div className={classes.btn4}></div>
      <div className={classes['card-int']}>
        <div className={classes.hello}>
          <video
            id='myVideo'
            alt='section-image'
            autoPlay
            loop
            muted
            playsInline
            src={'https://scigen-assets.s3.eu-west-1.amazonaws.com/insta-judge/heroVideo.mp4'}
            height={450}
          />
        </div>
      </div>
      <div className={classes.top}>
        {/* Sorry for that (not really sorry though) */}
        <div style={{ background: '#35373b', top: '8px', right: '140px', position: 'absolute', minHeight: '10px', minWidth: '50px' }}></div>
        <div style={{ background: '#35373b', top: '8px', left: '135px', position: 'absolute', minHeight: '10px', minWidth: '55px' }}></div>
        <div style={{ background: 'white', top: '73px', position: 'absolute', right: '75px', minHeight: '60px', minWidth: '110px' }}></div>
        <div style={{ background: 'white', top: '73px', position: 'absolute', left: '75px', minHeight: '60px', minWidth: '110px' }}></div>
        <div style={{ background: 'white', top: '73px', position: 'absolute', minHeight: '60px', minWidth: '110px' }}></div>
        <div style={{ background: 'white', top: '80px', position: 'absolute' }}>
          <img src={LOGON} />
        </div>
        <div className={classes.camera}>
          <div className={classes.int}></div>
        </div>
        <div className={classes.speaker}></div>
      </div>
    </div>
  );
};

export default IPhoneComponent;
