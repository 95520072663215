import React, { useState, useEffect, useContext, createContext } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const EventContext = createContext();

export const useEventContext = () => {
  return useContext(EventContext);
};

const EventProvider = ({ children }) => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [scoringMode, setScoringMode] = useState('');
  const [activeEventId, setActiveEventId] = useState(localStorage.getItem('activeEventId') || '');
  const [isLoading, setIsLoading] = useState(false);
  const [showGoToLeaderboardButton, setShowGoToLeaderboardButton] = useState(false);
  const [showPresenters, setShowPresenters] = useState(false);
  const [showGroupNames, setShowGroupNames] = useState(false);

  const eventId = activeEventId;

  const getEvent = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/api/events/get-by-id/${eventId}`);
      setName(data.name);
      setDescription(data.description);
      setScoringMode(data.scoringMode);
      setShowGoToLeaderboardButton(data.showGoToLeaderboardButton);
      setShowPresenters(data.showPresenters);
      setShowGroupNames(data.showGroupNames);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.error_message);
    }
    setIsLoading(false);
  };

  // TODO: rewrite this to have correct logic
  useEffect(() => {
    localStorage.setItem('activeEventId', activeEventId);
    if (activeEventId) {
      getEvent();
    } else {
      // navigate('/events')
    }
  }, [activeEventId]);

  return (
    <EventContext.Provider
      value={{
        name,
        description,
        scoringMode,
        setScoringMode,
        activeEventId,
        setActiveEventId,
        showGoToLeaderboardButton,
        setShowGoToLeaderboardButton,
        showPresenters,
        setShowPresenters,
        showGroupNames,
        setShowGroupNames,
      }}
    >
      {isLoading ? <CircularProgress /> : children}
    </EventContext.Provider>
  );
};

export default EventProvider;
